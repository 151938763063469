import { createSlice } from "@reduxjs/toolkit";

const applicationFormSlice = createSlice({
    name: "applicationForm",
    initialState: { profile_data: {} },
    reducers: {
        setApplicationForm: (state, action) => {
            state.profile_data = action.payload;

        },
        clearApplicationForm: (state, action) => {
            state.profile_data = [];
        },
    },
});

export const { setApplicationForm, clearApplicationForm } = applicationFormSlice.actions;

export default applicationFormSlice.reducer;

export const selectApplicationProfile = (state) => state.applicationForm.profile_data;