import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: { profile_data: {} },
  reducers: {
    setProfile: (state, action) => {
      state.profile_data = action.payload;
      
    },
    
    clearProfile: (state, action) => {
      state.profile_data = [];
    },
  },
});
export const { setProfile, clearProfile } = profileSlice.actions;

export default profileSlice.reducer;

export const selectProfile = (state) => state.profile.profile_data;
