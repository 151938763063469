import { useEffect, useState } from "react";
import { Box, Toolbar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QueryWrapper from "../components/QueryWrapper";
import SettingsDrawer from "../components/SettingsDrawer";
import { useSettings } from "../contexts/SettingsProvider";
import SideDrawer from "../components/SideDrawer";
import { useLocalStorage } from "../hooks/useLocalStorage";

const Layout = ({ children }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [user] = useLocalStorage("user", {});
  const [menuItems, setMenuItems] = useState([]);

  const { collapsed, open, toggleDrawer } = useSettings();

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  useEffect(() => {
    if (user?.user_role?.includes("candidate")) {
      setMenuItems([
        {
          icon: HomeIcon,
          key: "Home",
          path: "/home",
        },
        {
          icon: BarChartIcon,
          key: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: WorkIcon,
          key: "Jobs",
          path: "/candidatejobs",
        },
      ]);
    }
    if (user?.user_role?.includes("systemAdmin")) {
      setMenuItems([
        {
          icon: HomeIcon,
          key: "Home",
          path: "/home",
        },
        {
          icon: BusinessIcon,
          key: "Customer",
          path: "/customer",
        },
        {
          icon: WorkIcon,
          key: "Jobs Configuration",
          path: "/jobsadmin",
        },
      ]);
    }
    if (user?.user_role?.includes("admin")) {
      setMenuItems([
        {
          icon: HomeIcon,
          key: "Home",
          path: "/home",
        },
        {
          icon: BarChartIcon,
          key: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: SettingsSuggestIcon,
          key: "Management",
          path: "/management",
        },
      ]);
    }
    if (user?.user_role?.includes("recruiter")) {
      setMenuItems([
        {
          icon: HomeIcon,
          key: "Home",
          path: "/home",
        },
        {
          icon: BarChartIcon,
          key: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: WorkIcon,
          key: "Jobs",
          path: "/jobs",
        },
        {
          icon: PeopleIcon,
          key: "Candidates",
          path: "/candidates",
        },
        {
          icon: AccessTimeIcon,
          key: "Meeting Details",
          path: "/meetingdetails",
        },
        {
          icon: TroubleshootIcon,
          key: "Pipelines",
          path: "/pipelines",
        },
      ]);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <SideDrawer
        collapsed={collapsed}
        mobileOpen={open}
        onDrawerToggle={toggleDrawer}
        onSettingsToggle={handleSettingsToggle}
        menuItems={menuItems}
      />
      <SettingsDrawer
        onDrawerToggle={handleSettingsToggle}
        open={settingsOpen}
      />
      <Box component="main" sx={{ flexGrow: 1, pb: 3, px: { xs: 3, sm: 6 } }}>
        <Toolbar />
        <QueryWrapper>
          {/* <Outlet /> */}
          {children}
        </QueryWrapper>
      </Box>
    </Box>
  );
};

export default Layout;
