import { createSlice } from "@reduxjs/toolkit";

const industrySlice = createSlice({
  name: "industry",
  initialState: { industry_data: [] },
  reducers: {
    setIndustry: (state, action) => {
      state.industry_data = action.payload;
    },
    clearIndustry: (state, action) => {
      state.industry_data = [];
    },
    createNewIndustry: (state, action) => {
      state.industry_data = [action.payload, ...state.industry_data];
    },

    updatedIndustry: (state, action) => {
      const after_industry_update = state.industry_data.map((industry) => {
        if (industry.id === action.payload.industry_id) {
          return {
            ...industry,
            industry_name: action.payload.industry_name,
          };
        }
        return industry;
      });

      state.industry_data = after_industry_update;
    },

    deletedIndustry: (state, action) => {
      const deleted_industry_filtered = state.industry_data.filter(
        (industry) => industry.id !== action.payload.industry_id
      );
      state.industry_data = deleted_industry_filtered;
    },
  },
});

export const {
  setIndustry,
  clearIndustry,
  createNewIndustry,
  updatedIndustry,
  deletedIndustry,
} = industrySlice.actions;

export default industrySlice.reducer;

export const selectIndustry = (state) => state.industry.industry_data;
