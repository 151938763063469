import { createSlice } from "@reduxjs/toolkit";

const registerSlice = createSlice({
  name: "register",
  initialState: { registerData: null },
  reducers: {
    setRegistration: (state, action) => {
      state.registerData = action.payload;
    },
  },
});

export const { setRegistration } = registerSlice.actions;

export default registerSlice.reducer;

export const selectRegisterUser = (state) => state.register.registerData;//why?
