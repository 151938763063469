import { createSlice } from "@reduxjs/toolkit";

const candidateJobSlice = createSlice({
  name: "candidateJobs",
  initialState: { candidate_jobs: [] },
  reducers: {
    setFetchJobs: (state, action) => {
      state.candidate_jobs = action.payload;
    },
    setApplyJobs: (state, action) => {
      const appliedJobs = state.candidate_jobs.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            job_assign_status: action.payload.job_status,
          };
        }
        return job;
      });
      state.candidate_jobs = appliedJobs;
    },
  },
});
export const { setFetchJobs, setApplyJobs } = candidateJobSlice.actions;
export const selectFetchAllJobs = (state) => state.candidateJobs.candidate_jobs;
export default candidateJobSlice.reducer;
