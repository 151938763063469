import { lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import PrivateRoute from "../core/components/PrivateRoute";

// Auth
const ForgotPassword = lazy(() =>
  import("../features/auth/pages/ForgotPassword")
);
const ForgotPasswordSubmit = lazy(() =>
  import("../features/auth/pages/ForgotPasswordSubmit")
);
const Login = lazy(() => import("../features/auth/pages/Login"));
const Register = lazy(() => import("../features/register/pages/Register"));
const CompanyRegister = lazy(() =>
  import("../features/register/pages/CompanyRegister")
);

// Common
const Home = lazy(() => import("../features/Private/Common/Home/Home"));
const Profile = lazy(() =>
  import("../features/Private/Common/Profile/Profile")
);
const ProfileInformation = lazy(() =>
  import("../features/Private/Common/Profile/TapPages/ProfileInformation")
);
const ChangePassword = lazy(() =>
  import("../features/Private/Common/Profile/TapPages/ChangePassword")
);

const Customers = lazy(() =>
  import("../features/Private/SystemAdmin/pages/Customers")
);
const JobsAdmin = lazy(() =>
  import("../features/Private/SystemAdmin/pages/JobsAdmin")
);
// App Admin
const Management = lazy(() =>
  import("../features/Private/Admin/pages/Management")
);
const CustomerProfile = lazy(() =>
  import("../features/Private/Admin/pages/subpages/CustomerProfile")
);
const EmailConfiguration = lazy(() =>
  import("../features/Private/Admin/pages/subpages/EmailConfiguration")
);
const UserManagement = lazy(() =>
  import("../features/Private/Admin/pages/subpages/UserManagement")
);

const CodeIntegration = lazy(() =>
  import("../features/Private/Admin/pages/subpages/CodeIntegration")
);
// const Vendors = lazy(() => import("../features/Private/Admin/pages/Vendors"));
// const Clients = lazy(() => import("../features/Private/Admin/pages/Clients"));
// recruiter
const Jobs = lazy(() => import("../features/Private/Recruiter/pages/Jobs"));
const Candidates = lazy(() =>
  import("../features/Private/Recruiter/pages/Candidates")
);
const Pipelines = lazy(() =>
  import("../features/Private/Recruiter/pages/Pipelines")
);

const MeetingDetails = lazy(() =>
  import("../features/Private/Recruiter/pages/MeetingDetails")
);

// Candidate
const Dashboard = lazy(() =>
  import("../features/Private/Candidates/pages/Dashboard")
);

const CandidateJobs = lazy(() =>
  import("../features/Private/Candidates/pages/CandidateJobs")
);

// Core
const Forbidden = lazy(() => import("../core/pages/Forbidden"));
const NotFound = lazy(() => import("../core/pages/NotFound"));
const UnderConstructions = lazy(() =>
  import("../core/pages/UnderConstructions")
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* public routes */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route
          path="forgot-password-submit"
          element={<ForgotPasswordSubmit />}
        />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="org-register" element={<CompanyRegister />} />
        <Route path="under-construction" element={<UnderConstructions />} />
        <Route path="403" element={<Forbidden />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to={`/404`} replace />} />

        {/* Protected Routes */}
        <Route path="" element={<PrivateRoute />}>
          <Route path="home" element={<Home />} />
          <Route path="customer" element={<Customers />} />
          <Route path="jobsadmin" element={<JobsAdmin />} />
          {/* <Route
            path="dashboard"
            element={<Navigate to={`/under-construction`} replace />}
          /> */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="candidatejobs" element={<CandidateJobs />} />
          <Route path="profile" element={<Profile />}>
            <Route path="" element={<ProfileInformation />} />
            <Route path="password" element={<ChangePassword />} />
          </Route>
          {/* For app admin */}
          <Route path="management" element={<Management />}>
            <Route path="" element={<CustomerProfile />} />
            <Route path="emailconfig" element={<EmailConfiguration />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="code_integration" element={<CodeIntegration />} />
          </Route>
          {/* <Route path="vendors" element={<Vendors />} />
          <Route path="clients" element={<Clients />} /> */}
          {/* For Recruiter */}
          <Route path="jobs" element={<Jobs />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="meetingdetails" element={<MeetingDetails />} />
          <Route path="pipelines" element={<Pipelines />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
