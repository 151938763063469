import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import authReducer from "../../features/auth/authSlice";
import registerReducer from "../../features/register/registerSlice";
import customerRegisterReducer from "../../features/register/customerRegisterSlice";
import customerReducer from "../../features/Private/SystemAdmin/customers/customerSlice";
import userReducer from "../../features/Private/Admin/management/UserManagement/userManagementSlice";
import jobReducer from "../../features/Private/Recruiter/recruiterSlice/recruiterSlice";
import profileReducer from "../../features/Private/Common/Profile/ProfileSlice/ProfileSlice";
import applicationFormReducer from "../../features/Private/Recruiter/components/pipelines/ApplicationFormSlice/ApplicationFormSlice";
import emailConfigReducer from "../../features/Private/Admin/management/EmailConfiguration/emailConfigSlice";
import candidateReducer from "../../features/Private/Recruiter/candidateSlice/candidateSlice";
import jobAssignReducer from "../../features/Private/Recruiter/jobAssignSlice/jobAssignSlice";
import meetingReducer from "../../features/Private/Recruiter/meetingSlice/meetingSlice";
import featureCandidateReducer from "../../features/Private/Candidates/FeatureCandidateSlice/featureCandidateSlice";
import pipelineReducer from "../../features/Private/Recruiter/pipelineSlice/pipelineSlice";
import industryReducer from "../../features/Private/SystemAdmin/jobsadmin/industrySlice/industrySlice";
import functionalAreaReducer from "../../features/Private/SystemAdmin/jobsadmin/functionalAreaSlice/functionalAreaSlice";
import qualificationReducer from "../../features/Private/SystemAdmin/jobsadmin/qualificationSlice/qualificationSlice";
import candidateJobReducer from "../../features/Private/Candidates/CandidatesJobSlice/candidateJobSlice";
import codeIntegrationReducer from "../../features/Private/Admin/codeIntegrationSlice/codeIntegrationSlice"
//import vendorReducer from "../../features/Private/Admin/pages/vendorSlice/vendorSlice";
//import searchCustomerReducer from "../../features/Private/Admin/pages/components/searchCustomerAndSendRequest/searchCustomersSlice/searchCustomersSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    register: registerReducer,
    customerRegister: customerRegisterReducer,
    customer: customerReducer,
    user: userReducer,
    emailConfig: emailConfigReducer,
    jobs: jobReducer,
    profile: profileReducer,
    applicationForm: applicationFormReducer,
    candidate: candidateReducer,
    jobAssign: jobAssignReducer,
    meeting: meetingReducer,
    featureCandidate: featureCandidateReducer,
    pipeline: pipelineReducer,
    industry: industryReducer,
    functionalArea: functionalAreaReducer,
    qualification: qualificationReducer,
    candidateJobs: candidateJobReducer,
    codeIntegration: codeIntegrationReducer,
    // vendors: vendorReducer,
    //searchedcustomer: searchCustomerReducer
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(apiSlice.middleware),
  devTools: true,
});

