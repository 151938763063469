import { createSlice } from "@reduxjs/toolkit";
const pipelineSlice = createSlice({
    name: "pipeline",
    initialState: { pipeline_data: []},
    reducers: {
        setSections: (state, action) => {
            state.pipeline_data = action.payload
        },
        setPipelineData: (state, action) => {
            state.pipeline_data = action.payload
        }
    }
})

export const { setSections, setPipelineData } =
 pipelineSlice.actions

export default pipelineSlice.reducer;

export const selectPipeline = (state) => state.pipeline.pipeline_data;
