import { createSlice } from "@reduxjs/toolkit";

const candidateSlice = createSlice({
  name: "candidate",
  initialState: { candidates: [] },
  reducers: {
    getCandidates: (state, action) => {
      state.candidates = action.payload;
    },
  },
});
export const { getCandidates } = candidateSlice.actions;
export default candidateSlice.reducer;
export const selectCandidate = (state) => state.candidate.candidates;
