import React from "react";
import AppRoutes from "./Routes/AppRoutes";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <SettingsProvider>
        <QueryWrapper>
          <SnackbarProvider>
            <AppRoutes />
          </SnackbarProvider>
        </QueryWrapper>
      </SettingsProvider>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </React.Suspense>
  );
}


export default App;
