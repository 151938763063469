import { createSlice } from "@reduxjs/toolkit";
const jobSlice = createSlice({
  name: "jobs",
  initialState: { job_data: [] },
  reducers: {
    setJobs: (state, action) => {
      state.job_data = action.payload;
    },
    clearJob: (state, action) => {
      state.job_data = []
    },
    createNewJob: (state, action) => {
      state.job_data = [...action.payload, ...state.job_data];
    },

    updatedJob: (state, action) => {
      const after_job_update = state.job_data.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            job_name: action.payload.job_name,
            location: action.payload.location,
            job_status: action.payload.job_status,
            industry: action.payload.industry,
            skills: action.payload.skills,
            qualification: action.payload.qualification,
            offered_ctc: action.payload.offered_ctc,
            experience_needed: action.payload.experience_needed,
            employment_detail: action.payload.employment_detail,
            funcational_area: action.payload.funcational_area,
            job_description: action.payload.job_description,
            work_type: action.payload.work_type
          };
        }
        return job;
      });

      state.job_data = after_job_update;
    },
    selectRejectCandidate: (state, action) => {
      const updateData = state.job_data.map((job) => {
        if (job.job_id === action.payload.job_id) {
         job.assigned_candidates = job.assigned_candidates.map((candidate) => {
            if (candidate.candidate_id === action.payload.candidate_id) {
              return {
                ...candidate,
                candidate_status: 5,
              };
            } else {
              return {
                ...candidate,
                candidate_status: 6,
              };
            }
          });
        }
        return job
      });
   
      state.job_data = updateData;
    },
  },
});

export const { setJobs, clearJob, createNewJob, updatedJob, selectRejectCandidate } =
  jobSlice.actions;

export default jobSlice.reducer;

export const selectJobs = (state) => state.jobs.job_data;
