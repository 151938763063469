import { createSlice } from "@reduxjs/toolkit";
const qualificationSlice = createSlice({
  name: "qualification",
  initialState: { qualification_data: [] },
  reducers: {
    setQualification: (state, action) => {
      state.qualification_data = action.payload;
    },
    clearQualification: (state, action) => {
      state.qualification_data = [];
    },
    createNewQualification: (state, action) => {
      state.qualification_data = [action.payload, ...state.qualification_data];
    },

    updatedqualification: (state, action) => {
      const after_qualification_update = state.qualification_data.map(
        (qualification) => {
          if (qualification.id === action.payload.qualification_id) {
            return {
              ...qualification,
              qualification_name: action.payload.qualification_name,
            };
          }
          return qualification;
        }
      );

      state.qualification_data = after_qualification_update;
    },
    deletedqualification: (state, action) => {
      const deleted_qualification_filtered = state.qualification_data.filter(
        (qualification) => qualification.id !== action.payload.qualification_id
      );
      state.qualification_data = deleted_qualification_filtered;
    },
  },
});

export const {
  setQualification,
  clearQualification,
  createNewQualification,
  updatedqualification,
  deletedqualification,
} = qualificationSlice.actions;

export default qualificationSlice.reducer;

export const selectQualification = (state) =>
  state.qualification.qualification_data;
