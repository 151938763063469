import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router";
import Layout from "../pages/Layout";
import { useLocalStorage } from "../hooks/useLocalStorage";

const PrivateRoute = ({ children, ...routeProps }) => {
  const [user] = useLocalStorage("user", {});
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (user.access_token !== undefined) {
      navigate(`/home`, { replace: true });
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  // return user?.user_role?.find((role) => roles?.includes(role)) ? (
  //   <Layout>
  //     <Outlet />
  //   </Layout>
  // ) : (
  //   <Navigate to={`/login`} state={{ from: location }} replace />
  // );

  return user.access_token !== undefined ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={`/login`} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
