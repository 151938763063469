let ServerUrl_VAL = "";
let socketUrl = "";

switch (process.env.NODE_ENV) {
  case "development":
    // ServerUrl_VAL = "http://localhost:5002/api";
    ServerUrl_VAL = "http://137.135.97.40:5002/api";
    // socketUrl = "http://localhost:5003";
    socketUrl = "http://137.135.97.40:5003";
    break;
  case "production":
    ServerUrl_VAL = "backend/api";
    socketUrl = "socketnotification";
    break;
  default:
    // ServerUrl_VAL = "http://localhost:5002/api";
    ServerUrl_VAL = "http://137.135.97.40:5002/api";
    // socketUrl = "http://localhost:5003";
    socketUrl = "http://137.135.97.40:5003";
    break;
}

export const baseURL = ServerUrl_VAL;
export const socketEndPoint = socketUrl;
