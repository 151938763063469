import { createSlice } from "@reduxjs/toolkit";
const functionalAreaSlice = createSlice({
  name: "functionalArea",
  initialState: { functionalArea_data: [] },
  reducers: {
    setFunctionalArea: (state, action) => {
      state.functionalArea_data = action.payload;
    },
    clearFunctionalArea: (state, action) => {
      state.functionalArea_data = [];
    },
    createNewFunctionalArea: (state, action) => {
      state.functionalArea_data = [
        action.payload,
        ...state.functionalArea_data,
      ];
    },

    updatedFunctionalArea: (state, action) => {
      const after_functional_area_update = state.functionalArea_data.map(
        (func_area) => {
          if (func_area.id === action.payload.func_area_id) {
            return {
              ...func_area,
              functional_area_name: action.payload.functional_area_name,
            };
          }
          return func_area;
        }
      );

      state.functionalArea_data = after_functional_area_update;
    },

    deletedFunctionalArea: (state, action) => {
      const deleted_func_area_filtered = state.functionalArea_data.filter(
        (func_area) => func_area.id !== action.payload.func_area_id
      );
      state.functionalArea_data = deleted_func_area_filtered;
    },
  },
});

export const {
  setFunctionalArea,
  clearFunctionalArea,
  createNewFunctionalArea,
  updatedFunctionalArea,
  deletedFunctionalArea,
} = functionalAreaSlice.actions;

export default functionalAreaSlice.reducer;

export const selectFunctionalArea = (state) =>
  state.functionalArea.functionalArea_data;
