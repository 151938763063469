import { createSlice } from "@reduxjs/toolkit";

const customerRegisterSlice = createSlice({
  name: "customerRegister",
  initialState: { customerRegisterData: null },
  reducers: {
    setCustomerRegistration: (state, action) => {
      state.customerRegisterData = action.payload;
    },
  },
});

export const { setCustomerRegistration } = customerRegisterSlice.actions;

export default customerRegisterSlice.reducer;

export const selectCustomerRegisterUser = (state) =>
  state.customerRegister.customerRegisterData;
