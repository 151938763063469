import {
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../core/components/Logo";
import { drawerCollapsedWidth, drawerWidth } from "../../core/config/layout";
// import { useLocalStorage } from "../hooks/useLocalStorage";
import { useSelector } from "react-redux";
import {
  selectProfile,
  setProfile,
} from "../../features/Private/Common/Profile/ProfileSlice/ProfileSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useGetProfileQuery } from "../../features/Private/Common/Profile/ProfileSlice/ProfileApiSlice";

const SideDrawer = ({
  collapsed,
  mobileOpen,
  onDrawerToggle,
  onSettingsToggle,
  menuItems,
}) => {
  const location = useLocation();
  // const [user] = useLocalStorage("user", {});

  const dispatch = useDispatch();
  const [user] = useLocalStorage("user", {});

  const { data } = useGetProfileQuery({
    customer_id: user?.customer_id,
    user_id: user?.user_id,
  });

  const profile_data = useSelector(selectProfile);
  useEffect(() => {
    if (data && data?.data) {
      dispatch(setProfile(data?.data));
    }
  }, [dispatch, data]);

  const width = collapsed ? drawerCollapsedWidth : drawerWidth;

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100%" }}>
      <Logo sx={{ display: "flex", p: 4 }} />
      <List component="nav" sx={{ px: 2 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            component={NavLink}
            key={item.path}
            className={
              location.pathname === `${item.path}` ? "Mui-selected" : ""
            }
            end={true}
            to={item.path}
          >
            <ListItemAvatar>
              <Avatar sx={{ color: "inherit", bgcolor: "transparent" }}>
                <item.icon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.key}
              sx={{
                display: collapsed ? "none" : "block",
              }}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <List component="nav" sx={{ p: 2 }}>
        <ListItem button component={NavLink} to={"profile"}>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          {profile_data &&
            (user?.user_role.includes("candidate") ? (
              <ListItemText
                primary={`${profile_data?.candidate_details?.candidate_name}`}
                sx={{
                  display: collapsed ? "none" : "block",
                }}
              />
            ) : (
              <ListItemText
                primary={`${profile_data?.first_name} ${profile_data?.last_name}`}
                sx={{
                  display: collapsed ? "none" : "block",
                }}
              />
            ))}
        </ListItem>
        <ListItem button onClick={onSettingsToggle}>
          <ListItemAvatar>
            <Avatar>
              <SettingsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Settings"
            sx={{
              display: collapsed ? "none" : "block",
            }}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      aria-label="Candidate drawer"
      component="nav"
      sx={{
        width: { lg: width },
        flexShrink: { lg: 0 },
      }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: width,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: width,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideDrawer;
