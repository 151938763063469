import { createSelector, createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: { user_data: [] },
  reducers: {
    setUsers: (state, action) => {
      state.user_data = action.payload;
    },
    createNewUser: (state, action) => {
      state.user_data = [...state.user_data, action.payload];
    },
    setActivateOrDeactivateUser: (state, action) => {
      const modified_user = state.user_data.map((user) => {
        if (user.id === action.payload.customer_user_id) {
          return {
            ...user,
            is_active: action.payload.user_status,
          };
        }
        return user;
      });

      state.user_data = modified_user;
     
    },
    updateExistingUser: (state, action) => {
      const edited_user = state.user_data.map((user) => {
        if (user.id === action.payload.customer_user_id) {
          return {
            ...user,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            user_roles: [action.payload.user_role],
          };
        }
        return user;
      });

      state.user_data = edited_user;
    },
  },
});

export const {
  setUsers,
  createNewUser,
  setActivateOrDeactivateUser,
  updateExistingUser,
} = userSlice.actions;

export default userSlice.reducer;

export const selectUsers = (state) => state.user.user_data;

export const selectUserById = createSelector(
  [selectUsers, (state, user_id) => user_id],
  (users, id) => users.find((user) => user.id === id)
);
