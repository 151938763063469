import { createSlice } from "@reduxjs/toolkit";

const codeSlice = createSlice({
    name: "codeIntegration",
    initialState: {code_data: []},
    reducers: {
        setCodeData: (state, action) => {
            state.code_data = action.payload
        }
    }
})
export const {setCodeData} = codeSlice.actions
export const selectCodeData = (state) => state.codeIntegration.code_data
export default codeSlice.reducer