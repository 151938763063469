import { apiSlice } from "../../../../../core/api/apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: (data) => ({
        url: "/fetchprofile",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const updateProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/updateprofile",
        method: "PUT",
        body: { ...data },
      }),
    }),
  }),
});
export const { useGetProfileQuery } = profileApiSlice;
export const { useUpdateProfileMutation } = updateProfileApiSlice;
