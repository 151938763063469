import { createSlice } from "@reduxjs/toolkit";

const emailConfigSlice = createSlice({
  name: "emailconfig",
  initialState: { email_data: [] },
  reducers: {
    setFetchMail: (state, action) => {
      state.email_data = action.payload;
    },

    setCreateMail: (state, action) => {
      state.email_data = action.payload;
    },
    setUpdateMail: (state, action) => {
      state.email_data = action.payload;
    },
    setDefaultMail: (state, action) => {
      state.email_data = action.payload;
    },
    setClearMail: (state, action) => {
      state.email_data = [];
    },
  },
});

export const { setFetchMail, setCreateMail, setUpdateMail, setDefaultMail, setClearMail } =
  emailConfigSlice.actions;

export default emailConfigSlice.reducer;

export const selectEmailConfig = (state) => state.emailConfig.email_data;
