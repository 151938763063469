import { createSlice } from "@reduxjs/toolkit";

const featureCandidateSlice = createSlice({
  name: "featureCandidate",
  initialState: { jobs_assigned: [] },
  reducers: {
    setJobsAssigned: (state, action) => {
      state.jobs_assigned = action.payload;
    },
    setCandidateApprove: (state, action) => {
      const modified_job = state.jobs_assigned.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            customer_id: action.payload.customer_id,
            user_id: action.payload.user_id,
            job_customer_id: action.payload.job_customer_id,
            job_status: action.payload.response_status,
          };
        }
        return job;
      });

      state.jobs_assigned = modified_job;
    },
    setCandidateDecline: (state, action) => {
      const modified_job = state.jobs_assigned.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            customer_id: action.payload.customer_id,
            user_id: action.payload.user_id,
            job_customer_id: action.payload.job_customer_id,
            job_status: action.payload.response_status,
          };
        }
        return job;
      });

      state.jobs_assigned = modified_job;
    },
    setCandidateAcceptOffer: (state, action) => {
      const modified_job = state.jobs_assigned.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            job_customer_id: action.payload.customer_id,
            user_role: action.payload.user_role,
            candidate_id: action.payload.candidate_id,
            job_status: action.payload.acceptance_status,
            job_id: action.payload.job_id
          };
        }
        return job;
      });

      state.jobs_assigned = modified_job;
    },
    setCandidateDecineOffer: (state, action) => {
      const modified_job = state.jobs_assigned.map((job) => {
        if (job.job_id === action.payload.job_id) {
          return {
            ...job,
            job_customer_id: action.payload.customer_id,
            user_role: action.payload.user_role,
            candidate_id: action.payload.candidate_id,
            job_status: action.payload.acceptance_status,
            job_id: action.payload.job_id
          };
        }
        return job;
      });

      state.jobs_assigned = modified_job;
    },
  },
});

export const { setJobsAssigned, setCandidateApprove, setCandidateDecline, setCandidateAcceptOffer, setCandidateDecineOffer } =
  featureCandidateSlice.actions;

export default featureCandidateSlice.reducer;

export const selectJobsAssigned = (state) =>
  state.featureCandidate.jobs_assigned;
