import React from "react";
import { Button } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
// import { useQueryErrorResetBoundary } from "react-query";
import Loader from "./Loader";
import Result from "./Result";

const QueryWrapper = ({ children }) => {
  // const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      // onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <Result
          extra={
            <Button onClick={() => resetErrorBoundary()} variant="contained">
              Retry
            </Button>
          }
          status="error"
          subTitle="Error Sub Title"
          title="Error Title"
        />
      )}
    >
      <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};

export default QueryWrapper;
