import {
  Box,
  Drawer,
  Typography,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { drawerWidth } from "../config/layout";
import { useSettings } from "../contexts/SettingsProvider";

const SettingsDrawer = ({ onDrawerToggle, open }) => {
  const {
    changeCollapsed,
    // changeDirection,
    changeMode,
    collapsed,
    // direction,
    mode,
  } = useSettings();

  // const handleDirectionChange = (_, direction) => {
  //   changeDirection(direction);
  // };

  const handleModeChange = (_, mode) => {
    changeMode(mode);
  };

  const handleSidebarChange = (_, collapsed) => {
    changeCollapsed(collapsed);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onDrawerToggle}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
      variant="temporary"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h5">Settings</Typography>
        <IconButton color="inherit" onClick={onDrawerToggle} edge="end">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Typography gutterBottom id="settings-mode" marginTop={3} variant="h6">
          Mode
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          fullWidth
          onChange={handleModeChange}
        >
          <ToggleButton value="light">Light</ToggleButton>
          <ToggleButton value="dark">Dark</ToggleButton>
        </ToggleButtonGroup>
        {/* <Typography gutterBottom id="settings-mode" marginTop={3} variant="h6">
          Direction
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={direction}
          exclusive
          fullWidth
          onChange={handleDirectionChange}
        >
          <ToggleButton value="ltr">LTR</ToggleButton>
          <ToggleButton value="rtl">RTL</ToggleButton>
        </ToggleButtonGroup> */}
        <Typography
          gutterBottom
          id="settings-sidebar"
          marginTop={3}
          variant="h6"
        >
          Sidebar
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={collapsed}
          exclusive
          fullWidth
          onChange={handleSidebarChange}
        >
          <ToggleButton value={true}>Collapsed</ToggleButton>
          <ToggleButton value={false}>Full</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;
