import { createSlice } from "@reduxjs/toolkit";
const meetingSlice = createSlice({
  name: "meeting",
  initialState: { meeting_data: [] },
  reducers: {
    fetchMeetingdetails: (state, action) => {
      state.meeting_data = action.payload;
    },
    scheduleMeetingDetails: (state, action) => {
      const scheduling = state.meeting_data.map((meeting) => {
        if (meeting.id === action.payload.job_assignment_id) {
          return {
            ...meeting,
            id: action.payload.job_assignment_id,
            job_customer_id: action.payload.customer_id,
            meeting_date: action.payload.meeting_date,
          };
        }
        return meeting;
      });

      state.meeting_data = scheduling;
    },
  },
});
export const { fetchMeetingdetails, scheduleMeetingDetails } =
  meetingSlice.actions;
export default meetingSlice.reducer;
export const selectMeetingDetails = (state) => state.meeting.meeting_data;
