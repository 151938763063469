import { createSelector, createSlice } from "@reduxjs/toolkit";
const customerSlice = createSlice({
  name: "customer",
  initialState: { customer_data: [] },
  reducers: {
    setCustomers: (state, action) => {
      state.customer_data = action.payload;
    },
    setCustomerApprove: (state, action) => {
      const modified_customer = state.customer_data.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return {
            ...customer,
            is_registered: action.payload.acceptance_status,
            is_active: action.payload.acceptance_status,
          };
        }
        return customer;
      });

      state.customer_data = modified_customer;
    },
    setCustomerReject: (state, action) => {
      const modified_customer = state.customer_data.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return {
            ...customer,
            is_registered: action.payload.acceptance_status,
            is_active: action.payload.acceptance_status,
          };
        }
        return customer;
      });

      state.customer_data = modified_customer;
    },
    setActivateOrDeactiveCustomer: (state, action) => {
      const modified_customer = state.customer_data.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return {
            ...customer,
            is_active: action.payload.activation_status,
          };
        }
        return customer;
      });

      state.customer_data = modified_customer;
    },
    updateCustomerData: (state, action) => {
      const after_customer_update = state.customer_data.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return {
            ...customer,
            customer_name: action.payload.customer_name,
          };
        }
        return customer;
      });

      state.customer_data = after_customer_update;
    },
  },
});

export const {
  setCustomers,
  setCustomerApprove,
  setCustomerReject,
  setActivateOrDeactiveCustomer,
  updateCustomerData,
} = customerSlice.actions;

export default customerSlice.reducer;

export const selectCustomers = (state) => state.customer.customer_data;

export const selectCustomerByCustomerId = createSelector(
  [selectCustomers, (state, customerId) => customerId],
  (customers, customer_id) =>
    customers.find((customer) => customer.customer_id === customer_id)
);
