import { createSlice } from "@reduxjs/toolkit";

const jobAssignSlice = createSlice({
  name: "jobassign",
  initialState: { jobassign: {} },
  reducers: {
    getJobAssign: (state, action) => {
      state.jobassign = action.payload;
    },
  },
});
export const { getJobAssign } = jobAssignSlice.actions;
export default jobAssignSlice.reducer;
export const selectJobAssign = (state) => state.jobAssign.jobassign;
